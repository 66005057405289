.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* App.css */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: pink;
  overflow: hidden;
  /* Prevents scrolling */
}

.heart {
  position: fixed;
  font-size: 24px;
  animation: fall 10s linear infinite;
}

.center-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  color: rgb(186, 43, 43);
  text-align: center;
  font-family: 'Tangerine', cursive;
  font-weight: bold;
}

.audio-control,
.refresh {
  padding: 10px 10px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  outline: none;
  color: #fff;
  background-color: #f06292;
  border-radius: 5px;
  transition: background-color 0.3s;
  width: 80px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  align-items: center;
  justify-content: center;
}

.audio-control:hover,
.refresh:hover {
  background-color: #e03971;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.7;
  }
}

@keyframes fall {
  to {
    transform: translateY(100vh);
  }
}

.falling-item {
  position: fixed;
  top: 0;
  animation: fall 10s linear infinite, fadeIn 1s ease forwards;
  opacity: 0.6;
  /* Set a lower opacity */
  pointer-events: none;
}

.heart {
  font-size: 34px;
  color: pink;
  opacity: 0;
}

.image {
  width: 70px;
  height: auto;
  /* Make it a square or use 'auto' for automatic aspect ratio */
  border: 3px solid white;
  /* White frame */
  object-fit: cover;
  /* Initially set the image to be invisible to allow fadeIn */
  opacity: 0;
}